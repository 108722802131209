<template>
  <feedback-icon v-if="feedback" v-bind="feedback" class="input-feedback" />
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";

import FeedbackIcon, { FeedbackIconProps } from "@/components/icons/feedback-icon.vue";

export type Status = "success" | "failure";

export type FeedbackProps = { callback?: () => void; status: Status; title: string };

type Return = FeedbackProps | void;

export type FeedbackReturnType = Promise<Return> | Return;

const props = defineProps<FeedbackProps>();

const feedback = computed(
  (): FeedbackIconProps => ({
    theme: props.status === "success" ? "green" : "red",
    icon: props.status === "success" ? "check_circle" : "error",
    title: props.title,
  }),
);

onMounted(() => window.setTimeout(() => props.callback?.(), 1300));
</script>
